/* eslint-disable import/exports-last */
import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../components/floating-button/floating-button';
import Layout from '../../../components/layout';
import React from 'react';

const TrustedDocumentManagementPage = (props) => {
    const components = props.data.cms.tdmPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components}></DynamicZone>
        </Layout>
    );
};

export default TrustedDocumentManagementPage;

export const query = graphql`
    query ($locale: String!) {
        cms {
            tdmPageTemplate(locale: $locale) {
                components {
                    ... on STRAPI_ComponentComponentsHeader {
                        __typename
                        headerSubtitle: subtitle
                        title
                        contentOffset
                        mobileContentOffset
                        primaryImage {
                            url
                        }
                        secondaryImage {
                            url
                        }
                        backgroundImage {
                            url
                        }
                        backgroundImageHeight
                    }
                    ... on STRAPI_ComponentComponentsHorizontalScroll {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        imagesTailwindClasses
                        sectionTitle {
                            tailwindClasses
                            title
                            style
                        }
                        hasMobileGrayBackground
                        HorizontalScrollCaseStudy {
                            case_study {
                                link
                                logo {
                                    url
                                }
                                slug
                            }
                        }
                        HorizontalScrollBenefits {
                            image {
                                url
                            }
                            subtitle
                            title
                        }
                        horizontalScrollColor
                    }
                    ... on STRAPI_ComponentComponentsCardsWithDarkBackground {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        sectionTitle {
                            tailwindClasses
                            style
                            title
                        }
                        WhySolutionIsUniqueCard {
                            image {
                                url
                            }
                            subtitle
                            title
                        }
                        cardWithDarkBackgroundScrollColor
                    }
                    ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        tabsDarkBackground
                        tabHeight
                        sectionTitle {
                            tailwindClasses
                            description
                            style
                            title
                            ytLink
                        }
                        middleImage {
                            url
                        }
                        tabs {
                            subtitle
                            label
                            link
                            imagesMetadata
                            imagesTitles
                            scrollColor
                            labelImage {
                                url
                            }
                            images {
                                url
                            }
                            title
                            type
                            isLabelImageVisibleOnDesktop
                        }
                    }
                    ... on STRAPI_ComponentVerticalTabsVerticalTabs {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        sectionTitle {
                            tailwindClasses
                            ytLink
                            title
                            style
                            description
                        }
                        VerticalTabContent {
                            titleImage {
                                url
                            }
                            title
                            contentTexts
                            contentImages {
                                url
                            }
                        }
                        VerticalTabTitle {
                            title
                            image {
                                url
                            }
                        }
                    }
                    ... on STRAPI_ComponentUseCasesUseCasesSection {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        sectionTitle {
                            tailwindClasses
                            style
                            title
                        }
                        UseCasesColumns {
                            backgroundImage {
                                url
                            }
                            icon {
                                url
                            }
                            subtitle
                            title
                        }
                    }
                    ... on STRAPI_ComponentComponentsTheyTrustUs {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        theyTrustUsScrollColor
                        images {
                            url
                        }
                        sectionTitle {
                            tailwindClasses
                            style
                            title
                        }
                        mobileImages {
                            url
                        }
                        linesVisible
                    }
                    ... on STRAPI_ComponentComponentsSingleImage {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        image {
                            url
                        }
                    }
                    ... on STRAPI_ComponentSeoSeo {
                        __typename
                        seoDescription
                        seoLink
                        seoTitle
                        seoImages {
                            url
                        }
                    }
                }
            }
        }
    }
`;
